import React from 'react'

function NotFound() {
  return (
    <section className='full-section account-bg d-flex justify-content-center align-items-center'>
      <h1 className='text-white display-1'>Not Found</h1>
      
      </section>
  )
}

export default NotFound