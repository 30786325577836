import React from 'react'
import { Image } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'


function HeaderLogo({maxHeight,height}) {

  const navigate = useNavigate();

  return (
   <Image onClick={()=>navigate('')} src={'/logo/string-geo-logo-white.png'} alt='String Geo' style={{maxHeight:`${maxHeight}`,height:`${height}`,cursor:'pointer'}} fluid/>
  )
}

export default HeaderLogo