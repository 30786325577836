import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { setAccessToken, setIsActivePlan, setRefreshToken, setUser } from '../features/authSlice';
import { useDispatch } from 'react-redux';
import { useGetProfileMutation } from '../features/api';
import { hideLoading, showLoading } from '../features/loadingSlice';
import { getError } from '../utils/error';

const MobilePaymentRedirection = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [getProfile,{isLoading}] = useGetProfileMutation();

  const getAccount = async () => {
    try {
       dispatch(showLoading())
       const data = await getProfile().unwrap();
       dispatch(hideLoading())
       
       dispatch(setUser(data?.user));  
       dispatch(setIsActivePlan(data?.isActivePlan));  
       navigate('/auth/billing')

    } catch (error) {
        console.log('Error fetching profile', error);
        dispatch(hideLoading())
  
       getError(error);

    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    
    // const userString = queryParams.get('user');
    const accessToken = queryParams.get('accessToken');
    const refreshToken = queryParams.get('refreshToken');
    // const isActivePlan = queryParams.get('isActivePlan') === 'true';


    // const user = userString ? JSON.parse(decodeURIComponent(userString)) : null;

    // console.log('User:', user);
    console.log('Access Token:', accessToken);
    console.log('Refresh Token:', refreshToken);
    // console.log('Is Active Plan:', isActivePlan);

    if (accessToken && refreshToken) {
         // dispatch(setUser(user));
    dispatch(setAccessToken(accessToken));
    dispatch(setRefreshToken(refreshToken));
    // dispatch(setIsActivePlan(isActivePlan));
    getAccount();
    } else {
        navigate('/auth/signin')
    }
   

  }, [location]);

  return (
    null
  );
};

export default MobilePaymentRedirection;
